import { SetEnvConfig, environmentFileUrl } from "./config/environment/environment";

import { setAdobeEnvironment } from "./adobeAnalytics/config";

fetch(environmentFileUrl)
    .then((response) => response.json())
    .then((env) => {
        SetEnvConfig(env);
    }).then(() => {
     import("./bootstrap");
     setAdobeEnvironment(); 
})
.catch(() => {
    import("./404"); 
});